<template>
  <div class="container">
    <div class="wrapper">
      <div class="mt-10 w-100">
        <h1 class="text-uppercase">
          {{$t('label.ratingAndReviews')}} ({{ userRatings.total_rating_count }})
        </h1>
        <div class="row mt-5 ratingFilterRow">
          <div
            class="col-auto ratingFilter mr-3 mt-md-1 mt-3"
            :class="!ratingFilter ? 'active' : ''"
            @click="ratingFilter = ''"
          >
            {{$t('label.all')}}
          </div>
          <div
            v-for="(n, index) in 5"
            :key="index"
            class="col-auto d-flex ratingFilter mr-3 mt-md-1 mt-3"
            :class="ratingFilter === 5 - index ? 'active' : ''"
            @click="ratingFilter = 5 - index"
          >
            <div>
              {{ 5 - index }}
            </div>
            <div class="align-self-center ml-2">
              <v-img
                src="/assets/image/icons/ratingStarActive.png"
                class="rating-star"
                width="15"
              ></v-img>
            </div>
          </div>
        </div>
        <v-row no-gutters class="mt-10" v-show="getReviews.length">
          <v-col
            cols="12"
            md="6"
            v-for="(item, index) in getReviews"
            :key="index"
          >
            <div class="me-md-5 mb-8">
              <v-card height="180" outlined dark>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="d-flex py-2 pr-1">
                      <v-rating
                        hover
                        length="5"
                        readonly
                        size="20"
                        :value="item.rating"
                      ></v-rating>

                      <div class="ml-2 align-self-center">
                        {{ item.created_at | defaultDate }}
                      </div>
                      <v-menu
                        offset-y
                        dark
                        transition="slide-y-transition"
                        left
                        min-width="150"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon dark v-on="on" v-bind="attrs" class="ml-auto">
                            mdi-dots-horizontal
                          </v-icon>
                        </template>
                        <v-list class="text-center">
                          <v-list-item @click="report(item.id)">
                            <v-list-item-title>{{
                              $t('label.report')
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="d-flex my-3">
                      <v-avatar size="50" class="me-3">
                        <img :src="item.photo" crossorigin="anonymous"/>
                      </v-avatar>
                      <div class="align-self-center">{{ item.name }}</div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-2">
                      {{ item.comment }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
  import { PROFILE } from '@/api';
  import { bus } from '@/main.js';

  export default {
    name: 'RatingReviews',
    data: () => ({
      userRatings: {
        rating: 0,
        reviews: [],
        total_rating_count: 0
      },
      ratingFilter: ''
    }),
    computed: {
      getReviews() {
        if (!this.ratingFilter) return this.userRatings.reviews;
        return this.userRatings.reviews.filter(
          (x) => x.rating === this.ratingFilter
        );
      },
      avatarUrl() {
        return this?.$store?.getters['constants/avatarImageUrl'];
      },
      me() {
        return this.$store.state.auth.me.data;
      }
    },
    methods: {
      async loadData(id) {
        try {
          const response = await PROFILE.getUserRating(id);
          this.userRatings = response?.data;
          this.userRatings.reviews = response?.data?.reviews.map((r) => ({
            ...r,
            photo: r.photo ? r.photo.media_path : this.avatarUrl
          }))
          this.$closeModal();
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
      },
      report(itemId) {
        bus.$emit('report', {
          id: itemId,
          reportType: 'review'
        });
      }
    },
    mounted() {
      this.loadData(this.$route.params?.userId || this.me.id);
    }
  };
</script>

<style lang="scss">
  .ratingFilterRow.row {
    margin: 0;
    .ratingFilter {
      border: 1px solid #ffffff;
      padding-top: .5rem;
      padding-bottom: .5rem;
      min-width: 70px;
      text-align: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;

      &.active {
        background-color: #ff2ff2;
        border: none;
      }
    }
  }
  
  .v-rating .v-icon {
    padding: 0 !important;
  }
</style>
