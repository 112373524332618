import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"mt-10 w-100"},[_c('h1',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('label.ratingAndReviews'))+" ("+_vm._s(_vm.userRatings.total_rating_count)+") ")]),_c('div',{staticClass:"row mt-5 ratingFilterRow"},[_c('div',{staticClass:"col-auto ratingFilter mr-3 mt-md-1 mt-3",class:!_vm.ratingFilter ? 'active' : '',on:{"click":function($event){_vm.ratingFilter = ''}}},[_vm._v(" "+_vm._s(_vm.$t('label.all'))+" ")]),_vm._l((5),function(n,index){return _c('div',{key:index,staticClass:"col-auto d-flex ratingFilter mr-3 mt-md-1 mt-3",class:_vm.ratingFilter === 5 - index ? 'active' : '',on:{"click":function($event){_vm.ratingFilter = 5 - index}}},[_c('div',[_vm._v(" "+_vm._s(5 - index)+" ")]),_c('div',{staticClass:"align-self-center ml-2"},[_c(VImg,{staticClass:"rating-star",attrs:{"src":"/assets/image/icons/ratingStarActive.png","width":"15"}})],1)])})],2),_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.getReviews.length),expression:"getReviews.length"}],staticClass:"mt-10",attrs:{"no-gutters":""}},_vm._l((_vm.getReviews),function(item,index){return _c(VCol,{key:index,attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"me-md-5 mb-8"},[_c(VCard,{attrs:{"height":"180","outlined":"","dark":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"d-flex py-2 pr-1"},[_c(VRating,{attrs:{"hover":"","length":"5","readonly":"","size":"20","value":item.rating}}),_c('div',{staticClass:"ml-2 align-self-center"},[_vm._v(" "+_vm._s(_vm._f("defaultDate")(item.created_at))+" ")]),_c(VMenu,{attrs:{"offset-y":"","dark":"","transition":"slide-y-transition","left":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c(VList,{staticClass:"text-center"},[_c(VListItem,{on:{"click":function($event){return _vm.report(item.id)}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('label.report')))])],1)],1)],1)],1),_c(VListItemSubtitle,{staticClass:"d-flex my-3"},[_c(VAvatar,{staticClass:"me-3",attrs:{"size":"50"}},[_c('img',{attrs:{"src":item.photo,"crossorigin":"anonymous"}})]),_c('div',{staticClass:"align-self-center"},[_vm._v(_vm._s(item.name))])],1),_c(VListItemSubtitle,{staticClass:"mb-2"},[_vm._v(" "+_vm._s(item.comment)+" ")])],1)],1)],1)],1)])}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }